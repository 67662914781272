import React from 'react';
import './Hero.scss';

const Hero = () => {

  return (
    <section className="hero">
      <div className="hero-main" id='main'>
        <h1 className='mnText'>Добро пожаловать в Jerm Dating!</h1>
        <img src={'/assets/1.png'} alt="hero1" loading="lazy" />
        <h3>Первое и единственное Telegram-приложение для знакомств армян по всему миру!</h3>
      </div>
      <div className="hero-content rev">
        <div className='text'>
          <h3>Только настоящие люди — никаких фейков</h3>
          <p>В Jerm Dating нет места фальшивкам! Наши пользователи не боятся показать свое лицо. Каждый профиль проходит строгую верификацию личности. Забудьте о фейках и пустых аккаунтах — здесь вы встретите только настоящих людей с реальными фотографиями, готовых к искренним встречам и серьезным отношениям.</p>
          <div className='telegram'>

            <a href="https://t.me/jerm_dating_bot" className="telegram-link">
              Начать знакомиться
            </a>
          </div>
        </div>
        <img src={'/assets/2.png'} alt="hero2" loading="lazy" />
      </div>
      {/* <div id='payments'>
        <h1>Наши Услуги</h1>
        <div className="hero-content">
          <div className='text1'>
            <h3>Ваши возможности в Jerm Dating:</h3>
            <ul>
              <li>40 свайпов в день: Откройте для себя множество потенциальных партнеров ежедневно и найдите тех, кто действительно вам подходит.</li>
              <li>Переписка при взаимных симпатиях: Общайтесь с теми, кто разделяет ваши чувства и интересы, создавая крепкие и искренние связи.</li>
              <li>Доступ к анкетам тысяч армян со всего мира: Познакомьтесь с людьми, стремящимися создать счастливую семью, независимо от вашего местоположения.</li>
              <li>Уникальное и инновационное приложение: Наслаждайтесь современным интерфейсом и передовыми функциями, которые делают процесс знакомства простым и приятным.</li>
            </ul>
        <div className='div-img'><img src={img3} alt="hero" /></div> 

            <h3>Оформите подписку Jerm Black и откройте для себя расширенные возможности!</h3>
            <h5>Что входит в подписку Jerm Black</h5>
            <ul>
              <li>Неограниченное количество свайпов: Исследуйте потенциальные знакомства без ограничений.</li>
              <li>3 суперлайка в неделю: Подчеркните свою заинтересованность и выделитесь среди других.</li>
              <li>Доступ к разделу “Вы нравитесь”: Узнайте, кто проявил к вам интерес.</li>
              <li>Ваша анкета всегда в топе показов: Будьте на виду и увеличьте шансы на успешные знакомства.</li>
            </ul>
            <h5>Стоимость подписки Jerm Black</h5>
            <ul>
              <li>399 рублей за неделю</li>
              <li>899 рублей за месяц</li>
            </ul>
            <p>Суперлайки – ваш ключ к вниманию:</p>
            <p>Суперлайки позволяют сразу привлечь внимание и отправить сообщение человеку, которое появится в чате приложения вместе со ссылкой на ваш Telegram аккаунт.</p>
            <h5>Пакеты суперлайков</h5>
            <ul>
              <li>3 ⭐️ суперлайка – 199 рублей</li>
              <li>6 ⭐️ суперлайков – 397 рублей</li>
              <li>9 ⭐️ суперлайков – 555 рублей</li>
            </ul>
            <p>Почему стоит выбрать Jerm Black?</p>
            <p>Подписка Jerm Black значительно повышает ваши шансы на знакомство, выделяя вас среди других пользователей. Расширенные функции демонстрируют ваш серьезный настрой и позволяют эффективно взаимодействовать с потенциальными партнерами. Благодаря приоритетному отображению профиля и эксклюзивным возможностям, вы становитесь заметнее и привлекательнее для окружающих.</p>
            <p>Не упустите возможность улучшить свои знакомства! Оформите подписку Jerm Black уже сегодня и начните привлекать внимание прямо сейчас!</p>

            <h5>Реквизиты юридического лица</h5>
            <p>ИНН: 772987751190</p>

            <div className='telegram'>

              <a href="https://t.me/jerm_dating_bot" className="telegram-link">
                Начать знакомиться
              </a>
            </div>

          </div>
        </div>
      </div> */}
      <div className="hero-content rev">
        <div className='text'>
          <h3>Удобство и простота использования</h3>
          <p>Jerm Dating интегрирован в Telegram, что экономит ваше время и память устройства. Все функции доступны на привычной платформе без необходимости загружать дополнительные приложения. Мы создали интуитивно понятный и удобный сервис без лишних сложностей.</p>
          <p>Общение возможно только при взаимной симпатии — никаких назойливых пользователей! При совпадении симпатий вы сразу получаете доступ к профилям друг друга в Telegram, что позволяет вам полностью контролировать взаимодействие и чувствовать себя комфортно.</p>
          <p>Безопасность наших пользователей — наш приоритет. Мы защищаем ваши данные и обеспечиваем спокойное и безопасное общение с поддержкой на всех этапах.</p>
          <div className='telegram'>

            <a href="https://t.me/jerm_dating_bot" className="telegram-link">
              Начать знакомиться
            </a>
          </div>
        </div>
        <img src={'/assets/4.png'} alt="hero4" loading="lazy" />
      </div>
      <div className="hero-content">
        <img src={'/assets/5.png'} alt="hero5" loading="lazy" />
        <div className='text1'>
          <h3>Jerm Dating — для тех, кто ценит настоящее</h3>
          <p>В Jerm Dating встречаются люди, которые действительно ценят семейные ценности и традиции. Здесь собираются те, кто готов к искренним и осознанным отношениям. Если человек в Jerm Dating — значит, он разделяет эти принципы. Присоединяйтесь и начинайте строить настоящие отношения с теми, кто вам близок по духу.</p>
          <div className='telegram'>

            <a href="https://t.me/jerm_dating_bot" className="telegram-link">
              Начать знакомиться
            </a>
          </div>
        </div>
      </div>
      <div className="agreement" id='agreement'>
        <h1>Несколько советов для успешных знакомств!</h1>
        <div className="guideline">
          <div className='guideline-info'>
            <div className="info-block">
              <h3>Будьте собой!</h3>
              <p>Ваши фото, возраст и информация должны быть настоящими.
                <img src='/assets/megaphone.svg' alt='megaphone' loading="lazy" />
              </p>
            </div>
            <div className="info-block">
              <h3>Уважайте друг друга</h3>
              <p>Ведите себя культурно и уважайте других пользователей. Пожалуйста, воздержитесь от оскорблений и неуважительных высказываний.

                <img src='/assets/megaphone.svg' alt='megaphone' loading="lazy" />
              </p>
            </div>
            <div className="info-block">
              <h3>Сообщайте о нарушениях</h3>
              <p>Если вы столкнулись с неподобающим поведением, сообщите нам об этом, и наша команда незамедлительно примет меры.

                <img src='/assets/megaphone.svg' alt='megaphone' loading="lazy" />
              </p>
            </div>
            <div className="info-block">
              <h3>Совпадение симпатий</h3>
              <p>При совпадении симпатий пользователи получают доступ к Telegram-профилям друг друга и могут продолжить общение в личных сообщениях.

                <img src='/assets/megaphone.svg' alt='megaphone' loading="lazy" />
              </p>
            </div>
          </div>

          <img src={'/assets/6.png'} alt="hero2" loading="lazy" />
        </div>

      </div>
    </section>
  );
};

export default Hero;
