import React, { useState, useRef, useEffect } from 'react';
import './Header.scss';

const Header = () => {
  const menuRef = useRef(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsPopupOpen(false);
    }
  };

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupOpen]);

  return (
    <header>
      <div className="logo">
        <img className='jermlogo' src={'/assets/JermLogo.svg'} loading="lazy" alt='JermLogo' />
      </div>
      <button className="menu-button" onClick={toggleMobileMenu}>
        ☰
      </button>
      <ul className={`menu ${isMobileMenuOpen ? 'open' : ''}`}>
        <li><a href="#main" onClick={toggleMobileMenu}>Главная</a></li>
        {/* <li><a href="#payments" onClick={toggleMobileMenu}>Услуги</a></li> */}
        <li><a href="#" onClick={togglePopup}>Пользовательское Соглашение</a></li>
      </ul>
      <a href="https://t.me/jerm_dating_bot" className="telegram-link">
        Начать знакомиться
      </a>
      <div className="word">
        <img className='jermdating' src={'/assets/JermDating.svg'} loading="lazy" alt='JermDating' />
      </div>
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content" ref={menuRef}>
            <h3>Пользовательское соглашение для Jerm Dating</h3>
            <h3>
              Дата вступления в силу: 01.09.2024
            </h3>
            <h4>1. Общие положения</h4>
            <p>Настоящее Пользовательское соглашение (далее — «Соглашение») регулирует отношения между ИП Оганесян Мари Мартиковна (далее — «Компания») и пользователем (далее — «Пользователь»), касающиеся использования приложения Jerm Dating (далее — «Приложение»).</p>
            <p>Зарегистрировавшись и используя Приложение, Пользователь соглашается с условиями данного Соглашения. Если Пользователь не согласен с какими-либо положениями данного Соглашения, он обязан немедленно прекратить использование Приложения.</p>
            <h4>2. Регистрация и аккаунт</h4>
            <p>2.1. Пользователь обязан предоставить точные и актуальные данные при регистрации. Пользователь несет ответственность за безопасность своего аккаунта и сохранение конфиденциальности данных авторизации.</p>
            <p>2.2. Пользователь обязуется не передавать свои данные третьим лицам и использовать Приложение исключительно в личных целях.</p>
            <p>2.3. Компания оставляет за собой право заблокировать или удалить аккаунт Пользователя за нарушение условий Соглашения.</p>
            <h4>3. Использование Приложения</h4>
            <p>3.1. Пользователь обязуется использовать Приложение в соответствии с действующим законодательством и не совершать действий, нарушающих права и интересы других пользователей.</p>
            <p>3.2. В Приложении строго запрещено:
              <ul>
                <li>Оскорблять других пользователей.</li>
                <li>Выдавать чужие фотографии за свои.</li>
                <li>Загружать фотографии с посторонними людьми, а также с несовершеннолетними.</li>
                <li>Размещать в профиле менее трех фотографий.</li>
                <li>Регистрироваться в Приложении, находясь в браке.</li>
                <li>Регистрироваться в Приложении без серьезных намерений, таких как поиск длительных и серьезных отношений.</li>
                <li>Делать скриншоты внутри Приложения и распространять скриншоты с изображениями других пользователей.</li>
              </ul>
            </p>
            <p>3.3. В случае нарушения данных правил, администрация Приложения оставляет за собой право удалить анкету Пользователя навсегда без предварительного уведомления. Кроме того, Компания оставляет за собой право обратиться в суд для защиты прав пользователей и пресечения распространения их изображений. Все споры регулируются законодательством Российской Федерации.</p>
            <h4>4. Бесплатные функции</h4>
            <p>4.1. Пользователь может бесплатно использовать следующие функции Приложения:
              <ul>
                <li><b>40 свайпов в день:</b>Возможность знакомиться с большим количеством потенциальных партнеров ежедневно.</li>
                <li><b>Переписка при взаимных симпатиях:</b>Общение возможно только в случае взаимного интереса</li>
                <li><b>Доступ к анкетам тысяч армян со всего мира:</b>Возможность знакомиться с людьми по всему миру</li>
                <li><b>Уникальное и инновационное приложение:</b>Современный интерфейс и продвинутые функции для удобного использования.</li>
              </ul>
            </p>
            <h4>5. Платные услуги и подписка Jerm Black</h4>
            <p>5.1. Приложение предоставляет дополнительные платные услуги для повышения эффективности знакомства. Услуги включают:
              <ul>
                <li><b>Неограниченное количество свайпов:</b>Пользователь может свайпать без ограничений.</li>
                <li><b>3 суперлайка в неделю:</b>Возможность выделиться среди других пользователей и привлечь внимание.</li>
                <li><b>Доступ к разделу “Вы нравитесь”:</b>Просмотр пользователей, которым понравился ваш профиль.</li>
                <li><b>Анкета всегда в топе показов:</b>Приоритетное отображение профиля увеличивает шансы на успешные знакомства.</li>
              </ul>
            </p>
            <p>5.2. Стоимость подписки Jerm Black:
              <ul>
                <li>399 рублей за неделю</li>
                <li>899 рублей за месяц</li>
              </ul>
            </p>
            <p>5.3. Суперлайки: Суперлайки позволяют мгновенно привлечь внимание и отправить сообщение, которое отобразится в чате вместе со ссылкой на ваш Telegram аккаунт.</p>
            <p>
              Пакеты суперлайков:
              <ul>
                <li>3 суперлайка — 199 рублей</li>
                <li>6 суперлайков — 397 рублей</li>
                <li>9 суперлайков — 555 рублей</li>
              </ul>
            </p>
            <h4>6. Платежи и возврат средств</h4>
            <p>6.1. Оплата услуг осуществляется через Приложение. Платежи за подписки и другие платные услуги являются окончательными и не подлежат возврату, за исключением случаев, предусмотренных законодательством.</p>
            <p>6.2. Пользователь соглашается с тем, что отмена подписки возможна только в конце текущего оплаченного периода. Автоматическое продление подписки может быть отключено в настройках аккаунта.</p>
            <h4>7. Конфиденциальность и обработка данных</h4>
            <p>7.1. Компания обязуется соблюдать законодательство о защите персональных данных. Данные, предоставленные Пользователем, используются исключительно в целях предоставления услуг Приложения.</p>
            <p>7.2. Пользователь соглашается с обработкой его персональных данных, таких как имя, контактная информация, местоположение, фотографии и данные о действиях в Приложении.</p>
            <p>7.3. Подробности об обработке персональных данных описаны в Политике конфиденциальности, которая является неотъемлемой частью данного Соглашения.</p>

            <h4>8. Ответственность</h4>
            <p>8.1. Компания не несет ответственности за действия Пользователей в Приложении и их соответствие законодательству.</p>
            <p>8.2. Пользователь несет полную ответственность за свое поведение в Приложении, включая любые правонарушения, которые могут возникнуть вследствие его действий.</p>
            <p>8.3. Компания не гарантирует бесперебойную работу Приложения и не несет ответственности за сбои, связанные с техническими проблемами, интернет-соединением или работой сторонних сервисов.</p>

            <h4>9. Изменения в Соглашении</h4>
            <p>9.1. Компания оставляет за собой право изменять условия Соглашения в одностороннем порядке. Изменения вступают в силу с момента их публикации в Приложении.</p>
            <p>9.2. Продолжение использования Приложения после внесения изменений означает согласие Пользователя с новыми условиями.</p>

            <h4>10. Заключительные положения</h4>
            <p>10.1. Настоящее Соглашение регулируется законодательством Российской Федерации.</p>
            <p>10.2. Все споры, возникающие в связи с использованием Приложения, разрешаются в порядке, предусмотренном действующим законодательством Российской Федерации.</p>
            <button className="close-popup" onClick={togglePopup}>
              Закрыть
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
