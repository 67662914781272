import React from 'react';
import './Footer.scss';

const Footer = () => {

  return (
    <footer>

      <p>© 2024 Jerm Dating. All rights reserved.</p>


    </footer>
  );
};

export default Footer;
